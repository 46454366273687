<template>
	<div class="shop-detail">
		<van-swipe :autoplay="3000">
			<van-swipe-item v-for="(image, index) in shopDetail.slideshow" :key="index">
				<!-- <img class="shop-image" v-lazy="image" /> -->
				<van-image :src="imageUrl+image" alt="" class="shop-image" lazy-load />
			</van-swipe-item>
		</van-swipe>
		<div class="shop-information">
			<div class="shop-title">
				<div style="padding-right: 10px;">
					<span class="shop-name">{{shopDetail.name}}</span>
					<span style="font-size: 14px;">营业时间：</span><span
						class="shop-time">{{shopDetail.business_start_time}} - {{shopDetail.business_end_time}}</span>
				</div>

			</div>
			<div class="shop-address-phone">
				<div style="width: 60%;">
					<span class="shop-address">{{shopDetail.address}}</span>
				</div>
				<van-icon name="like" size="30" @click="followShop" v-if="is_follow" color="#ffa000" />
				<van-icon name="like-o" size="30" @click="followShop" v-else />
				<van-icon name="chat-o" size="30" @click="goChatPage" />
				<van-icon name="phone-o" size="30" />
				<van-icon name="share-o" size="30" @click="shareStore" />
			</div>
		</div>
		<!-- <div class="buy-btn"><span>买单</span></div> -->
		<!-- <van-tabs v-model="active" sticky @click="toggle"> -->
		<!-- <van-tab :title="item.title" v-for="item in tabData"> -->
		<!-- <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh"> -->
		<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10" @load="onLoadList"
			style="padding:15px;">

			<div v-for="item in goodsList" class="goods-item" v-if="dataStatus" @click="goGoodsDetail(item.id)">
				<div class="goods-image">
					<img :src="imageUrl+ item.image" alt="">
				</div>

				<div class="goods-info">

					<span class="goods-name">{{item.name}}</span>
					<div class="goods-info-bottom">
						<span class="goods-price">￥{{item.price}}</span>
						<!-- <van-stepper  integer /> -->
						<div class="add-cart-icon" @click.stop="addShoppingCart(item)"><span>+</span></div>
					</div>
				</div>

			</div>
			<van-empty :image="emptyImg" description="暂无商品" v-else />
		</van-list>

		<!-- </van-pull-refresh> -->

		<!-- </van-tab> -->
		<!-- </van-tabs> -->
		<van-action-sheet v-model:show="show">
			<div class="goods-sku-content">
				<div class="goods-sku-top">
					<van-image :src="imageUrl+goodsSku.image"></van-image>
					<div class="goods-sku-information">
						<!-- <p class="goods-sku-name">{{goodsDetail.name}}</p> -->
						<p class="goods-sku-price"><span style="font-size: 14px;">￥</span>{{price}}</p>
						<p class="goods-sku-stock">库存:{{stock}}</p>
					</div>
				</div>
				<div class="goods-sku-attrs">
					<div class="goods-sku-attr">
						<p class="goods-sku-title">{{goodsSku.attrs.attr[0].attr_name}}</p>

						<div v-for="(item,i) in goodsSku.attrs.value" @click="seleteSku(item,i)" ref="sku"
							:class="seleteSkuStatus==i?'checkedSku':'sku-attr-item'">
							<div class="goods-sku-value"><span>{{item.sku}}</span></div>
						</div>

					</div>
					<div class="goods-sku-attr">
						<p class="goods-sku-title">数量</p>
						<van-stepper v-model="goodsNum" :max="stock" />
					</div>
				</div>
				<van-button type="primary" block color="#ffa000" @click="skuComfirm">确定</van-button>
			</div>

		</van-action-sheet>
		<div class="shop-cart" @click="goShoppingCart">
			<!-- <van-icon name="shopping-cart" size="35" color="#ffa000" :badge="cartNum" v-if="cartNum > 0" /> -->
			<van-icon name="shopping-cart" size="35" color="#ffa000" />
		</div>
	</div>
</template>

<script>
	import {
		Lazyload
	} from 'vant';
	import {
		getIconList,
		getGoodsList
	} from '@/api/index'
	import {
		getShopDetail,
		addShoppingCart,
		getCartList,
		followShop
	} from '@/api/shop'
	import {
		getShopGoodsList
	} from '@/api/goods'

	import {
		concernDel
	} from '@/api/user'


	export default {
		name: 'shopDetail',
		inject: ['reload'],
		data() {
			return {
				value: 1,
				images: [
					'https://img01.yzcdn.cn/vant/apple-1.jpg',
					'https://img01.yzcdn.cn/vant/apple-2.jpg',
				],
				active: 0,
				imageUrl: this.url.imageUrl,
				tabData: [{
						title: '团购',
						type: 1
					},
					{
						title: '外卖',
						type: 2
					},
					// {
					// 	title: '预约',
					// 	type: 3
					// }
				],

				nullTip: '空空如也~',
				limit: 10, // 每页条数
				page: 1, // 页码
				type: 1,
				goodsList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				goodsNumber: '',
				cartNum: 0,
				dataStatus: true,
				emptyImg: require('@/assets/zwsp.png'),
				shopDetail: '',
				is_follow: false,
				title: '',
				show: false,
				goodsSku: '',
				seleteSkuStatus: 0,
				stock: 0,
				price: 0,
				goodsNum: 1,
				skuUnion: '',
				goodsId: ''
			};
		},
		mounted() {
			console.log(window.location)
			let params = {}
			params.id = this.$route.query.id
			params.lng = 113.6496429
			params.lat = 34.7566109
			getIconList().then((res) => {
				this.iconList = res.data
			})
			this.getShopGoodsList()
			this.getShopDetail(params)

		},
		//修改页面title
		// beforeRouteLeave(to) {
		// 	console.log(to)
		// 	to.meta.title = this.shopDetail.name
		// },
		methods: {
			//选择规格确认
			skuComfirm() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				console.log(this.goodsId)
				var paramas = {}
				paramas.count = this.goodsNum
				paramas.id = this.goodsId
				paramas.union = this.skuUnion
				console.log(paramas)
				addShoppingCart(paramas).then((res) => {
					if (res.code == 200) {
						this.cartNum = res.data.count
						this.$toast('成功加入购物车,请点击查看')
						this.show = false
					}
					this.$toast.clear()
				})
			},
			//选择商品规格
			seleteSku(item, i) {
				console.log(item)
				this.seleteSkuStatus = i
				// this.$refs.sku.addclass
				this.price = item.price
				this.stock = item.stock
				this.skuUnion = item.union
			},
			//分享店铺
			shareStore() {
				let shareData = {}
				shareData.title = this.shopDetail.name
				shareData.desc = this.shopDetail.address
				shareData.picUrl = this.imageUrl + this.shopDetail.image
				console.log(shareData)
				let that = this
				upsdk.pluginReady(function() {

					upsdk.showSharePopup({
						title: shareData.name,
						desc: shareData.address,
						shareUrl: window.location.href,
						picUrl: shareData.picUrl,

					});
				});
			},
			//聊天页
			goChatPage() {
				// console.log(this.$route.query.id)
				let sid = this.$route.query.id
				this.$router.push({
					path: '/chatPage',
					query: {
						sid: sid,
						storeName: this.shopDetail.name
					}
				})
			},
			//关注店铺
			followShop() {
				if (this.is_follow) {
					concernDel({
						sid: this.$route.query.id
					}).then((res) => {

						if (res.code == 200) {
							this.is_follow = !this.is_follow
							this.$toast('取消成功')
							// this.reload()
						}

					})
				} else {
					followShop({
						sid: this.$route.query.id
					}).then((res) => {

						if (res.code == 200) {
							this.is_follow = !this.is_follow
							this.$toast('关注成功')

							// this.reload()
						}
					})
				}
			},
			//跳转商品详情
			goGoodsDetail(id) {
				this.$router.push({
					path: '/goodsDetail',
					query: {
						id: id
					}
				})
			},
			//跳转购物车
			goShoppingCart() {
				this.$router.push({
					path: '/shopCart',
					query: {
						shopId: this.$route.query.id
					}
				})
			},

			//添加购物车
			addShoppingCart(item) {
				this.goodsSku = item
				this.goodsId = item.id
				if (item.attrs) {
					this.show = true
					console.log(item)
					this.price = item.attrs.value[0].price
					this.stock = item.attrs.value[0].stock
					this.skuUnion = item.attrs.value[0].union

				} else {
					this.skuComfirm()
				}

				// let params = {}
				// params.id = item.id
				// addShoppingCart(params).then((res) => {
				// 		if (res.data) {
				// 			this.cartNum = res.data.count
				// 			this.$toast('添加成功')
				// 		}
				// 	}

				// )
			},
			//切换选项
			toggle(name) {

				this.type = name + 1
				this.page = 1
				this.limit = 10
				this.upFinished = false
				this.goodsList = []
				this.getShopGoodsList()
			},
			//店铺详情
			getShopDetail(params) {
				getShopDetail(params).then((res) => {
					console.log(res)
					this.shopDetail = res.data
					this.cartNum = res.data.cart_count
					this.is_follow = res.data.is_follow
				})
			},
			//商品列表
			getShopGoodsList() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.sid = this.$route.query.id
				// params.type = this.type
				params.page = this.page
				params.limit = this.limit
				getShopGoodsList(params).then((res) => {
					console.log(res)
					const rows = res.data.data

					if (rows.length === 0) {
						// 加载结束
						this.upFinished = true
						if (this.page == 1) {

							this.dataStatus = false

						}
						return
					} else {
						this.dataStatus = true
					}

					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据

					if (this.page === 1) {
						this.goodsList = rows

					} else {
						this.goodsList = this.goodsList.concat(rows)

					}
					this.$toast.clear()
				})
			},
			onDownRefresh() {

			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.getShopGoodsList()

			},

		}
	};
</script>

<style lang="less" scoped>
	.shop-detail {
		background-color: #F6F6F6;

		.checkedSku {
			background-color: #F2F2F2;
			color: red;
			display: flex;
			align-items: center;
			justify-content: center;

			padding: 3px 15px;
			margin-right: 10px;
		}

		.goods-sku-content {
			padding: 10px 15px;

			.goods-sku-attrs {
				margin-bottom: 20px;

				p {
					margin: 0;
				}

				.goods-sku-attr {
					display: flex;
					align-items: center;
					margin-top: 20px;

					.goods-sku-title {
						margin-right: 20px;
					}

					.sku-attr-item {
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #F6F6F6;
						padding: 3px 15px;
						margin-right: 10px;
					}
				}
			}

			.goods-sku-top {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				.van-image {
					width: 88px;
					height: 88px;
				}

				.goods-sku-information {
					display: flex;
					margin-left: 30px;
					flex-direction: column;

					.goods-sku-name {
						font-size: 18px;
					}

					.goods-sku-stock {
						color: #666666;
						font-size: 14px;
						margin-top: 10px;
					}

					.goods-sku-price {
						color: red;
						font-size: 24px;
					}

					p {
						margin: 0;
					}
				}
			}
		}

		.options-area {
			display: flex;
			flex-wrap: wrap;
			background-color: #FFFFFF;

			.options {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 20%;
				margin-bottom: 10px;

				.options-icon {
					width: 55px;
					height: 55px;

				}

				.options-name {
					margin-top: 5px;
					font-size: 12px;
					color: #666666;
				}
			}
		}

		.goods-item {
			display: flex;
			align-items: flex-start;
			background-color: #FFFFFF;
			border-radius: 5px;
			margin-bottom: 10px;
			padding: 5px 15px;
			height: 100px;
			box-sizing: border-box;
			justify-content: flex-start;

			.goods-image {
				width: 100px;
				height: 88px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					-webkit-background-size: cover;
					-moz-background-size: cover;
					border-radius: 8px;
				}
			}

			.goods-info {
				display: flex;
				flex-direction: column;
				height: 100%;
				padding-left: 10px;
				width: calc(100% - 110px);
				justify-content: space-between;

				.goods-info-bottom {
					display: flex;
					align-items: center;
					width: 100%;
					justify-content: space-between;

					.add-cart-icon {
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #ffa000;
						width: 18px;
						height: 18px;
						border-radius: 3px;
						color: #FFFFFF;
					}
				}

				.goods-price {
					font-size: 18px;
					font-weight: bold;
					color: red;
				}

				.goods-name {
					font-size: 14px;

					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}

		.shop-image {
			width: 100%;
			height: 280px;
		}

		.shop-information {
			padding: 10px 15px;
			background-color: #FFFFFF;

			.shop-title {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.shop-time {
					color: #666666;
					font-size: 14px;
				}

				.shop-name {
					margin-bottom: 15px;
					font-size: 18px;
					font-weight: bold;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}

			.shop-address-phone {
				margin-top: 5px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.shop-address {
					font-size: 12px;
				}
			}
		}

		.buy-btn {
			width: 96%;
			height: 44px;
			margin: 30px auto;

			border-radius: 20px;
			background-color: #ffa000;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				color: #FFFFFF;
				font-size: 16px;
			}
		}

		.shop-cart {
			position: fixed;
			bottom: 30px;
			width: 50px;
			height: 50px;
			right: 50px;
			border-radius: 50%;
			background-color: #FFFFFF;
			box-shadow: 2px 2px 5px #888888;
			display: flex;
			// justify-content: center;
			align-items: center;
		}
	}

	.van-icon {
		margin-left: 10px;
	}
</style>
